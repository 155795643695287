import React from 'react';
import { Link, graphql } from "gatsby"
import "../css/@wordpress/block-library/build-style/style.css"
import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} tigitle={siteTitle}>
    <Seo title="Home" />
    <article
      className="global-wrapper blog-post"
      itemScope
      itemType="http://schema.org/Article"
    >




    <div class="jumbotron p-4 p-md-5 text-black rounded bg-light">
          <div class="col-md-6 px-0">
            <h1 class="display-4 font-italic">Ressenyes de Llibres en Català</h1>
            <p class="lead my-3">Descobreix les millors ressenyes de llibres en català.</p>
            <p class="lead mb-0"><Link to="/llibres/" class="text-white font-weight-bold">Veure més</Link></p>
          </div>
      </div>
      
      <main role="main" class="mt-4 container">
        <div class="row">
          <div class="col-md-8 blog-main">
            <h3 class="pb-4 mb-4 font-italic border-bottom">
            Benvinguts
            </h3>
      
            <div class="blog-post">
              <h2 class="blog-post-title">Benvinguts a Gremi Llibre Vell.cat</h2>
              <hr />
              <p>Benvinguts a Llibre Vell, la millor pàgina de ressenyes de llibres en català! A la nostra pàgina podràs trobar opinions de tota mena de llibres, des de clàssics fins a novetats, perquè puguis triar el que més t'agradi.</p>
              <p>Aqui podràs trobar informació detallada sobre tots els llibres que estaran disponibles en català i els seus autors, així com activitats i esdeveniments especials que se celebraran durant l'any.</p>
              <p>No us perdeu l'oportunitat de descobrir els tresors que tenim a la nostra pàgina! Esperem veure't aviat a la nostra web!</p>
            </div>
      
          </div>
      
          <aside class="col-md-4 blog-sidebar">
            <div class="p-4 mb-3 bg-light rounded">
              <h4 class="font-italic">About Us</h4>
              <p>A Llibre Vell, estimem els llibres en català i volem compartir aquest amor amb tots vosaltres. La nostra pàgina va ser creada per oferir ressenyes de llibres i recomanacions de tota mena, perquè puguis trobar el llibre perfecte per a tu. </p>
              <p>A Llibre Vell, treballem dur per oferir-te la millor experiència de lectura possible. El nostre equip està compost per experts en llibres i aficionats a la lectura, que seleccionen amb cura cada llibre que recomanem i escriuen ressenyes honestes i detallades. </p>
              <p>Si tens alguna pregunta o suggeriment, no dubtis a posar-te en contacte amb nosaltres. Estarem encantats d'ajudar-te a trobar el llibre perfecte per a tu! </p>

            </div>
      
          </aside>
      
        </div>
      
      </main>
    </article>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
